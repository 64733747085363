import React from "react"
import Layout from "../components/layout"
import { Backdrop } from "../styledComponent/sc"

export default function cart() {
  return (
    <Layout>
      <Backdrop></Backdrop>
    </Layout>
  )
}
